import { useMemo } from 'react'
import { Flex, Stack, Box, Icon } from '@storyofams/react-ui'
import { defineMessages, useIntl } from 'react-intl'
import { useQuery } from 'react-query'

import { Divider, Button, Heading, Drawer } from '~components'
import { useShopify } from '~context'
import { formatPrice } from '~lib'
import { CartThin } from '~components/common/Icons'

import { shopifySdk } from '~lib/shopify/client'
import { Text } from '../Text'
import { CartLineItem } from './CartLineItem'

const messages = defineMessages({
  mealBox: 'meal box',
  editMeals: 'Edit meals',
  euroPerMeal: 'euro per meal',
  minimumNotReach: 'Select minimum 6 meals to continue',
  bagIsEmpty: 'Your bag is empty',
  goShopping: 'Go Shopping',
  overview: 'Order overview',
  selectMeals: 'Select meals',
  boxes: 'boxes',
  meals: 'meals',
  normalPortion: 'Normal',
  xlPortion: 'XL',
  yourOrder: 'Your order',
})

export const CartOverlay = ({ isOpen, close }) => {
  const intl = useIntl()
  const { cart, toggleCartOverlay, quantity, portion } = useShopify()

  const hasThemeBox = cart?.lines?.edges?.some(({ node }) =>
    node?.merchandise?.product?.tags.includes('is_box'),
  )

  const enableCheckout = quantity >= 6 || hasThemeBox

  const subscriptionPrice = useMemo(() => {
    const lineItems = cart?.lines?.edges || []
    let subscriptionPrice = 0

    if (lineItems) {
      lineItems.forEach(li => {
        const firmhouseProductPrice =
          li?.node?.merchandise?.firmhouseProductPrice?.value
        const variantPrice = li?.node?.merchandise?.priceV2?.amount
        const quantity = li?.node?.quantity

        if (firmhouseProductPrice) {
          subscriptionPrice += Number(firmhouseProductPrice) * quantity
        } else {
          subscriptionPrice += Number(variantPrice) * quantity
        }
      })
    }

    return {
      amount: subscriptionPrice,
      currencyCode: cart?.cost?.totalAmount?.currencyCode,
    }
  }, [quantity, cart?.cost?.totalAmount?.amount])

  return (
    <Drawer
      isOpen={isOpen}
      close={close}
      title={intl.formatMessage(messages.yourOrder)}
      header={
        !!quantity && (
          <Flex
            flexDirection={['column', 'row']}
            justifyContent="space-between"
            alignItems={{ sm: 'center' }}
            mt={4}
          >
            <Heading variant="h6" as="h6">
              {portion != 'selectable'
                ? `${intl.formatMessage(
                    portion === 'normal'
                      ? messages.normalPortion
                      : messages.xlPortion,
                  )} ${intl.formatMessage(messages.mealBox)}`
                : ''}
            </Heading>

            {!hasThemeBox && (
              <Button
                variant="link"
                to="/meals?ordering=true"
                onClick={() => toggleCartOverlay(false)}
                alignSelf={['flex-start', 'initial']}
                mt={[4, 0]}
              >
                {intl.formatMessage(messages.editMeals)}
              </Button>
            )}
          </Flex>
        )
      }
    >
      {cart?.lines?.edges?.length ? (
        <Flex flex={1} flexDirection="column">
          <Box flex={1} width="100%">
            <Stack space={3} flexDirection="column">
              {cart?.lines?.edges?.map(({ node }) => {
                return <CartLineItem key={node.id} cartItem={node} small />
              })}
            </Stack>
            <Divider color="grey400" mt={4} mb={[2, 3]} />

            {enableCheckout && (
              <Flex flexDirection="column" width="100%" alignItems="flex-end">
                <Flex>
                  {subscriptionPrice.amount !=
                  Number(cart?.cost?.totalAmount?.amount) ? (
                    <>
                      <Text
                        fontWeight="bold"
                        fontSize={3}
                        lineHeight="normal"
                        mr={2}
                      >
                        {formatPrice(subscriptionPrice)}
                      </Text>
                      <Text
                        fontWeight="bold"
                        fontSize={3}
                        lineHeight="normal"
                        color="grey500"
                        css={{ textDecoration: 'line-through' }}
                      >
                        {formatPrice(cart?.cost?.totalAmount)}
                      </Text>
                    </>
                  ) : (
                    <Text
                      fontWeight="bold"
                      fontSize={3}
                      lineHeight="normal"
                      color="grey500"
                      css={{ textDecoration: 'line-through' }}
                    >
                      {formatPrice(cart?.cost?.totalAmount)}
                    </Text>
                  )}
                </Flex>

                <Text lineHeight="normal" mt={2}>
                  {`${quantity} ${intl.formatMessage(
                    hasThemeBox ? messages.boxes : messages.meals,
                  )}`}
                </Text>

                {!hasThemeBox && (
                  <Text
                    variant="s"
                    lineHeight="normal"
                    color="grey500"
                    mt={0.5}
                  >
                    {`${(subscriptionPrice.amount / quantity)?.toFixed(
                      2,
                    )} ${intl.formatMessage(messages.euroPerMeal)}`}
                  </Text>
                )}
              </Flex>
            )}
          </Box>

          {!enableCheckout && (
            <Text textAlign="center" mb={[2, 1.5]}>
              {intl.formatMessage(messages.minimumNotReach)}
            </Text>
          )}

          <Button
            width="100%"
            to={enableCheckout ? '/order/overview' : '/meals?ordering=true'}
            variant={enableCheckout ? 'primary' : 'secondary'}
            border="2px"
            onClick={() => {
              toggleCartOverlay(false)
            }}
          >
            {enableCheckout
              ? intl.formatMessage(messages.overview)
              : intl.formatMessage(messages.selectMeals)}
          </Button>
        </Flex>
      ) : (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Icon icon={CartThin} fontSize={10} mb={5} />

          <Text fontWeight="bold" fontSize={2.25} mb={2}>
            {intl.formatMessage(messages.bagIsEmpty)}
          </Text>

          <Button
            width="100%"
            // to="/order/select-box"
            to="/meals"
            variant="primary"
            onClick={() => {
              toggleCartOverlay(false)
            }}
          >
            {intl.formatMessage(messages.goShopping)}
          </Button>
        </Flex>
      )}
    </Drawer>
  )
}
