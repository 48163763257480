import { Flex, css, Box, SystemProps } from '@storyofams/react-ui'
import { FormattedMessage, defineMessages } from 'react-intl'

import { Image, Button, Text, Heading } from '~components'
import { useShopify } from '~context'
import { BaseCartLine } from '~lib/shopify/sdk'
import { QuantityInput } from './QuantityInput'
import { gaRemoveFromCartEvent, formatPrice } from '~lib'

const messages = defineMessages({
  remove: 'Remove',
})

export const CartLineItem = ({
  cartItem,
  small,
  ...props
}: {
  cartItem: any
  small?: any
} & SystemProps) => {
  const { removeLineItem } = useShopify()

  return (
    <Flex alignItems="center" flexWrap={['wrap', 'nowrap']} {...props}>
      <Flex>
        <Box
          position="relative"
          flexShrink={0}
          mr={[1.5, 3]}
          width={small ? [40, 54] : [64, 80]}
          height={small ? [40, 54] : [64, 80]}
        >
          <Image
            src={cartItem?.merchandise?.image?.url}
            alt={
              cartItem?.merchandise?.image?.altText ||
              cartItem?.merchandise?.product?.title ||
              cartItem?.merchandise?.title
            }
            objectFit="contain"
            layout="fill"
            display="flex"
            width="100%"
            height="100%"
          />
        </Box>

        <Box width="100%" position="relative">
          <Flex flexDirection={'column'} gap={1}>
            <Box>
              <Heading
                variant="sh4"
                as="h4"
                flex={1}
                style={{ overflowWrap: 'anywhere', display: 'inline-block' }}
                mr={'0.5em'}
              >
                {cartItem?.merchandise?.product?.title ||
                  cartItem?.merchandise?.title}
              </Heading>
              {cartItem?.merchandise?.product?.tags.includes(
                'portion_selectable',
              ) ? (
                <Text
                  display={'inline-block'}
                  fontSize={small ? 12 : 14}
                  fontWeight={['extraBold', 'bold']}
                  lineHeight={'1.5em'}
                  flex={1}
                >
                  ({cartItem?.merchandise?.selectedOptions[0].value})
                </Text>
              ) : (
                ''
              )}
            </Box>

            <Flex alignItems={'center'} gap={2}>
              <Text fontSize={'22px'} color={'#21857F'}>
                {formatPrice(cartItem?.merchandise?.priceV2)}
              </Text>
              <Button
                variant="unstyled"
                color="grey500"
                onClick={() => {
                  const portion = cartItem?.merchandise.selectedOptions.find(
                    el => el.name === 'Portion',
                  )
                  const gaData = {
                    currency: cartItem?.merchandise?.priceV2?.currencyCode,
                    amount:
                      Number(cartItem?.merchandise?.priceV2?.amount) *
                      cartItem?.quantity,
                    items: [
                      {
                        item_id: cartItem?.merchandise?.sku,
                        item_name: cartItem?.merchandise?.product?.title,
                        item_brand: cartItem?.merchandise?.product?.vendor,
                        item_variant: portion?.value,
                        price: Number(cartItem?.merchandise?.priceV2?.amount),
                        quantity: cartItem?.quantity,
                      },
                    ],
                  }

                  gaRemoveFromCartEvent(gaData)
                  removeLineItem(cartItem?.id)
                }}
                transition="0.2s color ease-out"
                fontSize={1.5}
                css={css({
                  textTransform: 'uppercase',
                  '&:hover, &:focus': {
                    color: 'grey900',
                  },
                })}
              >
                <FormattedMessage {...messages.remove} />
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>

      <QuantityInput
        cartItem={cartItem}
        quantity={cartItem?.quantity}
        ml={'auto'}
        mr={0}
        pl={3}
        px={0}
        small
        outline
      />
    </Flex>
  )
}
