const currencySymbol = {
  EUR: '€',
  XXX: '',
}

export const formatPrice = (price: {
  amount: string | number
  currencyCode: string
}) => {
  if (!price) return 0

  const value = Number(price?.amount)
  const formattedPrice = Number.isInteger(value)
    ? `${value.toFixed(0)}.-`
    : value.toFixed(2)

  return `${currencySymbol[price.currencyCode]}${formattedPrice}`
}
