import { useState, useEffect, useMemo } from 'react'
import { css, Flex, Icon, SystemProps } from '@storyofams/react-ui'
import { pick } from '@styled-system/props'
import { startsWith } from 'lodash'

import { Button, Heading } from '~components'
import { useShopify } from '~context'
import { CartFragmentFragment, BaseCartLine, Product } from '~lib/shopify/sdk'
import { Minus, Plus } from '../Icons'
import { gaAddToCartEvent, gaRemoveFromCartEvent } from '~lib'

const StyledButton = ({ type = 'minus', outline, ...props }) => (
  <Button
    variant="unstyled"
    display="flex"
    alignItems="center"
    justifyContent="center"
    p={0}
    border="1px solid"
    borderColor={outline ? '#21857F' : '#69A8A2'}
    bg={outline ? 'transparent' : '#69A8A2'}
    borderRadius="4px"
    css={css({
      '&:active': {
        borderColor: 'grey700',
        color: 'grey700',
      },
    })}
    {...props}
  >
    <Icon
      icon={type === 'plus' ? Plus : Minus}
      color={outline ? '#69A8A2' : '#FFFFFF'}
      fontSize={3}
    />
  </Button>
)

const giftCardTag = 'gift_card'

export const QuantityInput = ({
  cartItem,
  small,
  outline,
  ...props
}: {
  cartItem?: BaseCartLine
  quantity?: any
  small?: boolean
  outline?: boolean
  disabled?: boolean
  useLocalProductQuantity?: boolean
} & SystemProps) => {
  const [quantity, setQuantity] = useState(props?.quantity)
  const productData = useMemo(() => {
    return cartItem ? (cartItem?.merchandise?.product as Product) : null
  }, [cartItem?.merchandise?.product])
  const giftCardMode = useMemo(() => {
    return productData
      ? !!productData?.tags.find(el => el == giftCardTag)
      : null
  }, [productData])

  const gaAddToCart = (
    variant: Partial<
      CartFragmentFragment['lines']['edges'][number]['node']['merchandise']
    >,
  ) => {
    gaAddToCartEvent({
      currency: cartItem?.merchandise?.price?.currencyCode,
      amount: cartItem?.merchandise?.price?.amount,
      items: [
        {
          item_id: cartItem?.merchandise?.sku,
          item_name: cartItem?.merchandise?.product?.title,
          item_brand: cartItem?.merchandise?.product?.vendor,
          item_variant: cartItem?.merchandise?.title,
          price: Number(cartItem?.merchandise?.price?.amount),
          quantity: 1,
        },
      ],
    })
  }

  const gaRemoveToCart = (
    variant: Partial<
      CartFragmentFragment['lines']['edges'][number]['node']['merchandise']
    >,
  ) => {
    gaRemoveFromCartEvent({
      currency: cartItem?.merchandise?.price?.currencyCode,
      amount: cartItem?.merchandise?.price?.amount,
      items: [
        {
          item_id: cartItem?.merchandise?.sku,
          item_name: cartItem?.merchandise?.product?.title,
          item_brand: cartItem?.merchandise?.product?.vendor,
          item_variant: cartItem?.merchandise?.title,
          price: Number(cartItem?.merchandise?.price?.amount),
          quantity: 1,
        },
      ],
    })
  }

  const {
    adjustLineItemQuantity,
    addItemToCart,
    updatingCart,
    quantity: totalQuantity,
  } = useShopify()

  useEffect(() => {
    setQuantity(props.quantity)
  }, [props.quantity])

  const updateQuantity = amount => {
    if (totalQuantity >= 24 && amount > quantity) return

    setQuantity(amount)
    if (!props?.useLocalProductQuantity) {
      adjustLineItemQuantity({
        id: cartItem.id,
        quantity: amount,
      })
    }
  }

  const isPlusButtonDisabled = giftCardMode
    ? !cartItem?.merchandise?.availableForSale
    : cartItem?.merchandise?.quantityAvailable < quantity + 1

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      cursor={updatingCart ? 'wait !important' : undefined}
      px={1}
      mx="auto"
      maxWidth={180}
      gap={1}
      {...pick(props)}
    >
      <StyledButton
        width={small ? 32 : [32, 45]}
        height={small ? 32 : [32, 45]}
        disabled={quantity <= 0 || props?.disabled}
        onClick={() => {
          updateQuantity(quantity - 1)
          gaRemoveToCart(cartItem?.merchandise)
        }}
        outline={outline}
        cursor={updatingCart ? 'wait!important' : undefined}
      />

      <Heading as="h4" variant="sh4" width={40} textAlign="center">
        {props.quantity}
      </Heading>

      <StyledButton
        type="plus"
        width={small ? 32 : [32, 45]}
        height={small ? 32 : [32, 45]}
        disabled={
          isPlusButtonDisabled || totalQuantity >= 24 || props?.disabled
        }
        outline={outline}
        onClick={() => {
          if (!quantity) {
            addItemToCart(
              [
                {
                  merchandiseId: cartItem?.merchandise?.id,
                  quantity: 1,
                },
              ],
              cartItem?.merchandise?.product?.tags?.find(t =>
                startsWith(t, 'is_'),
              ),
            )

            gaAddToCart(cartItem?.merchandise)
          } else {
            updateQuantity(quantity + 1)
            gaAddToCart(cartItem?.merchandise)
          }
        }}
        cursor={updatingCart ? 'wait!important' : undefined}
      />
    </Flex>
  )
}
